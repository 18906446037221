import { z } from 'zod';
import { treatment_program } from '../constants/common';

const myAvailabilitySchema = z.string()
  .refine(value => value !== '', {
    message: "Please select your availability."
  })
  .refine(value => ["accepting_new_clients", "not_accepting_clients"].includes(value), {
    message: "Invalid selection. Please choose 'Accepting clients' or 'Not accepting clients'."
  });

const clientsCurrentlyAcceptingSchema = z
  .array(z.number().int())


const availableSessionTypesSchema = z
  .array(z.enum([
    'has_video_provided',
    'inperson_session',
    'psypact_provider'
  ]))

const groupAcceptingFromSchema = z.enum([
  "everyone",
  "only_from_my_connections",
  "only_from_my_favorites"
]);

export const fullFormSchema = z.object({
  current_status: myAvailabilitySchema,
  currently_served: clientsCurrentlyAcceptingSchema.optional(),
  session_type: availableSessionTypesSchema.optional(),
  accepting_referrals_from: groupAcceptingFromSchema.optional(),
  account_type: z.string()
}).superRefine((data, ctx) => {
  if (data.current_status === "accepting_new_clients") {
    if (!data.currently_served?.length && data.account_type !== treatment_program) {
      ctx.addIssue({
        path: ["currently_served"],
        message: "Please select one or more client type.",
      });
    }
    if (!data.session_type?.length && data.account_type !== treatment_program) {
      ctx.addIssue({
        path: ["session_type"],
        message: "Please select one or more session type.",
      });
    }
    if (!data.accepting_referrals_from?.length) {
      ctx.addIssue({
        path: ["accepting_referrals_from"],
        message: "Please select one or more group.",
      });
    }
  }
});
