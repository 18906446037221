import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useForm } from "react-hook-form";
import { ReactComponent as RadioButtonIcon } from "../../../assets/Icons/radio-button.svg";
import { ReactComponent as RadioButtonMarkedIcon } from "../../../assets/Icons/radio-button-marked.svg";
import { useOnboarding } from "../../../contexts/onboarding";
import {
  independent_mental_health_professional,
  referring_physician,
  treatment_program,
} from "../../../constants/common";
import "./style.css";
import CustomButton from "../../CustomButton";

const accountTypes = {
  [independent_mental_health_professional]: "Independent Mental Health Professional",
  [referring_physician]: "Referring Physician",
  [treatment_program]: "Treatment Program",
};

const BusinessDescription = ({ payment, TP }) => {
  const { next, onboardingState, updateOnboardingState, setOnboardingState } =
    useOnboarding();
  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      account_type: onboardingState.account_type || "",
    },
  });

  const accountType = watch("account_type");

  function clearUserData() {
    setOnboardingState((prevState) => ({
      email: prevState.email,
      password: prevState.password,
      first_name: prevState.first_name,
      last_name: prevState.last_name,
      account_type: prevState.account_type,
    }));
  }

  function handleAccountTypeChange(type) {
    setValue("account_type", type);
    updateOnboardingState({ account_type: type });
    if (onboardingState.account_type !== type) clearUserData();
  }

  function onSubmit() {
    if (accountType) {
      next();
    }
  }

  return (
    <Container maxWidth="md">
      <div className="cardShadow">
        <Typography variant="h5" mb={3}>
          Which of the following describes your business?
        </Typography>

        {Object.entries(accountTypes).map(([type, label]) => (
          <Button
            key={type}
            variant="outlined"
            color="secondary"
            fullWidth
            sx={{
              color: "text.primary",
              mb: 3,
              gap: "6px",
              justifyContent: "flex-start",
              minHeight: 65,
              fontSize: 16,
              fontWeight: 400,
            }}
            disabled={(TP === "1" && type !== treatment_program) || (payment === "Special_Offer" && type === treatment_program)}
            startIcon={
              accountType === type ? (
                <RadioButtonMarkedIcon />
              ) : (
                <RadioButtonIcon />
              )
            }
            onClick={() => handleAccountTypeChange(type)}
          >
            {label}
          </Button>
        ))}

      </div>

      <Box pt={5} textAlign="center">
        <CustomButton
          className="btnContinue"
          variant="contained"
          sx={{
            minWidth: 212,
          }}
          onClick={handleSubmit(onSubmit)}
          disabled={!accountType}
          name='Continue'
          page='Register'
          component='Step - 2 Business Description'
          eventName='Onboadring'
        >
          Continue
        </CustomButton>
      </Box>
    </Container>
  );
};

export default BusinessDescription;
