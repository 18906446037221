import mixpanel from 'mixpanel-browser';

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { debug: true });

export const identifyUser = (user) => {
  if (!user || !user.id) {
    console.error('User object is required to identify.');
    return;
  }

  mixpanel.identify(user.id);
  
  mixpanel.people.set({
    email: user.email || '',
    name: `${user.first_name} ${user.last_name}` || '',
    first_name: user.first_name || '',
    last_name: user.last_name || '',
    role: user.role || '',
    last_login: new Date(),
  });
};

export const trackRouteChange = (pathname) => {
  mixpanel.track('Tracking Route', {
    page: pathname,
  });
};

export const trackClickEvent = (eventName='Button Clicked', additionalData = {}) => {
  mixpanel.track(eventName, additionalData);
};
